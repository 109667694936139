import React from 'react';
import ModalVideo from 'react-modal-video';
import {Link} from 'gatsby'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const RoomBanner = () => {
    // Popup Video
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    // Owl Carousel
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId='bk7McNUjWgw' 
                onClose={() => setIsOpen(!isOpen)} 
            />

            {display ? <OwlCarousel 
                className="home-slides owl-carousel owl-theme"
                {...options}
            > 


<div className="main-banner-item5 item-bg9">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>260 Pentonville Road, London, N1 9JY</p>    
                            </div>
                        </div>
                    </div>
                </div>


                <div className="main-banner-item5 item-bg10">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>260 Pentonville Road, London, N1 9JY</p>    
                            </div>
                        </div>
                    </div>
                </div>


                <div className="main-banner-item5 item-bg11">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>260 Pentonville Road, London, N1 9JY</p>    
                            </div>
                        </div>
                    </div>
                </div>


         <div className="main-banner-item5 item-bg7">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>260 Pentonville Road, London, N1 9JY</p>    
                            </div>
                        </div>
                    </div>
                </div>
    
<div className="main-banner-item5 item-bg6">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <p>114 New Cavendish Street London, W1W 6XT</p>
                            </div>
                        </div>
                    </div>
                </div>

           
                
                <div className="main-banner-item5 item-bg3">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>114 New Cavendish Street London, W1W 6XT</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-banner-item5 item-bg8">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>260 Pentonville Road, London, N1 9JY</p>    
                            </div>
                        </div>
                    </div>
                </div>

              

                <div className="main-banner-item5 item-bg4">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>114 New Cavendish Street London, W1W 6XT</p> 
                            </div>
                        </div>
                    </div>
                </div>


                <div className="main-banner-item5 item-bg5">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                            <p>114 New Cavendish Street London, W1W 6XT</p>    
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel> : ''}
        </React.Fragment>
    )
}

export default RoomBanner;