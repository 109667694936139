import React from "react"
import Navbar from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/MainBanner"
import OurServices from "../components/Index/OurServices"
import Locations from "../components/Index/locations"
import Rooms from "../components/Index/Rooms"
import Findus from "../components/Index/Find"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import Faq from "../components/Index/Faq"
import About from "../components/Index/Aboutm"
import Whats from '../components/whatsapp'

const Home = () => {
  return (
    <>
    <Layout>
      <Navbar />
      <Banner />
      <About />
      <Rooms />
      <OurServices />
      <Locations />
      <Faq />
     
    
  
            <ContactInfo />
      <Footer />
     
    </Layout>
    <Whats />
     </>
  )
}

export default Home