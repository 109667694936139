import Minmap from '../../assets/images/minmap.jpg'
import React from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"
import blog1 from "../../assets/images/minmap.jpg"
import blog5 from "../../assets/images/minimap2.jpg"
import blog6 from "../../assets/images/blog/blog-img6.jpg"
import user1 from "../../assets/images/user1.jpg"
import user2 from "../../assets/images/user2.jpg"
import user3 from "../../assets/images/user3.jpg"

const OurBlog = () => {
  return (
    <>
      <section className="blog-area pt-100 pb-70 bg-fffbf5">
        <div className="container">
        <div className="content">
                                   
                                    
                                    <h2>Our Locations</h2> <br />
                                    </div>

          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-blog-post">
                <div className="post-image">
                <a href="https://www.google.com/maps/place/114+New+Cavendish+St,+London+W1W+6XT/@51.5200941,-0.147063,17z/data=!3m1!4b1!4m6!3m5!1s0x48761ad5e3fc100d:0x78166a735cff5b65!8m2!3d51.5200909!4d-0.1421921!16s%2Fg%2F11c1yzrkw6?entry=ttu">
                    <img src={blog1} alt="blog" />
                  </a>
                </div>

                <div className="post-content">
                
                  <h3>
                    <a href="https://www.google.com/maps/place/114+New+Cavendish+St,+London+W1W+6XT/@51.5200941,-0.147063,17z/data=!3m1!4b1!4m6!3m5!1s0x48761ad5e3fc100d:0x78166a735cff5b65!8m2!3d51.5200909!4d-0.1421921!16s%2Fg%2F11c1yzrkw6?entry=ttu">
                    114 New Cavendish Street London, W1W 6XT
                    </a>
                  </h3> <br />
                
                                   
                  <p className="smalltext"> Getting to this location is incredibly convenient, as there are five underground stations all within a short 5-minute walk.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-blog-post">
                <div className="post-image">
                  <a href="https://www.google.com/maps/place/260+Pentonville+Rd,+London+N1+9JY/@51.5310826,-0.1228772,17z/data=!3m1!4b1!4m6!3m5!1s0x48761b3f29876947:0xdfc2d241a0e093d8!8m2!3d51.5310793!4d-0.1203023!16s%2Fg%2F11c5n50xwp?entry=ttu">
                    <img src={blog5} alt="blog" />
                  </a>
                </div>

                <div className="post-content">
                
                  <h3>
                  <a href="https://www.google.com/maps/place/260+Pentonville+Rd,+London+N1+9JY/@51.5310826,-0.1228772,17z/data=!3m1!4b1!4m6!3m5!1s0x48761b3f29876947:0xdfc2d241a0e093d8!8m2!3d51.5310793!4d-0.1203023!16s%2Fg%2F11c5n50xwp?entry=ttu">
                    260 Pentonville Road, London, N1 9JY
                    </a>
                  </h3> <br />
                
                                   
                  <p className="smalltext">Getting to us is a breeze; we're just a 2-minute walk from Kings Cross Station.</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  )
}

export default OurBlog
