import React from 'react'

import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'
import Minmap from '../../assets/images/minmap.jpg'

import {Link} from 'gatsby'

const Find = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div id="find" className="about-area pt-70 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                         
                           
                   <img src={Minmap} width="100%"/>               
                                                </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                   
                                    
                                    <h2>How to find us</h2>
                                    <h5>Address</h5>
                                    <p>114 New Cavendish Street London, W1W 6XT</p>
                                    <h5>Underground</h5>
                                    <p>Great Portland Street Station (Hammersmith & City line, Circle line, Metropolitan line) <p className="smalltext">6 minute walk</p></p>
<p>Goodge Street Station (Northern line) <p className="smalltext">7 minute walk</p></p>
<p>Oxford Circus Station (Central line) <p className="smalltext">7 minute walk</p></p>
<p>Regent’s park Station (Bakerloo line) <p className="smalltext">10 minute walk</p></p>

<h5>Train</h5>
<p>Euston railway station <p className="smalltext"> 12 minute walk</p> </p>

                                    <h5>Bus Links</h5>
                                    <p>88, 453, 12, 22, 113, 18, 27, 30, 205<p className="smalltext">All within a maximum 6 minute walk</p></p>
                                    

                                    <h5>Car PArking</h5>
                                    <p>There are pay and display bays outside the building.
</p>

                                


                                    
                                 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
           
         
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default Find;