import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'
import {Link} from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div id="rates" className="about-area pt-70 pb-70">
                <div className="container">
                    <div className="row ">
                      
                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <h2>Our Treatment Room Rates</h2> <br />
                                    


                                    
                                  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                    
<h6><b>Regular Booking Rates</b></h6>
<p>If you're looking to book a room for a day, multiple days, or
even an entire month, don't hesitate to get in touch with us
today. We're here to accommodate your unique scheduling
needs and provide you with a tailored solution for your clinic
space requirements. <br /> <br /> <Link to="/#contact">Contact us</Link>  now to discuss your booking
options and experience the flexibility and convenience of
Minsony.</p>
<table>
 
  <tbody>
 
    <tr>
    <td data-column="Last Name">Full Day</td>
     
      <td data-column="Job Title">From £75</td>
   
    </tr>
   
 
    <tr>
    <td data-column="Last Name">Full Month</td>
    
      <td data-column="Job Title">From £1,200</td>
   
    </tr>
    
    <br />
  </tbody>
  
</table>

</div>

<br />
<div className="col-lg-6 col-md-12">
<h6><b>Ad-hoc Rates</b></h6>
<p>No hidden fees; you pay only for the time you need,
starting from just 1 hour. Leave behind the need for
commitment and embrace the freedom of flexibility. <br />
Book conveniently online with Minsony.




</p>
                  
                  <table>

<tbody>
    <tr>
        <td></td>
        <td>New<br /> Cavendish St</td>
        <td>King's Cross</td>
    </tr>
<tr>
<td data-column="Last Name">10+ Hours</td>
<td data-column="Job Title">£18</td>
<td data-column="Job Title">£15</td>
</tr>

<tr>
<td data-column="Last Name">6+ Hours</td>
<td data-column="Job Title">£22</td>
<td data-column="Job Title">£18</td>

</tr>


<tr>
<td data-column="Last Name">3+ Hours</td>
<td data-column="Job Title">£25</td>
<td data-column="Job Title">£20</td>
</tr>


<tr>
<td data-column="Last Name">2 Hours</td>
<td data-column="Job Title">£30</td>
<td data-column="Job Title">£25</td>
</tr>

<tr>
<td data-column="Last Name">1 Hour</td>
<td data-column="Job Title">£40</td>
<td data-column="Job Title">£30</td>
</tr>

</tbody>


</table>
<p>
Please note all prices above are per hour
</p>
<br />
<p>For regular adhoc bookings  <AnchorLink 
                                to="/#contact"
                                activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                
                                >contact us</AnchorLink></p>


<Link to="https://minsony.skedda.com/booking" className="default-btn">
                     
                     Book Room                                <span></span>
                                                 </Link>



                           
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
           
         
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;
