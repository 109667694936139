import React from "react";
import styled from "styled-components";



import imgC4 from "../assets/images/whatsapp.svg";


const SectionNew = styled.div`
position: fixed;
bottom: 15px;
right: 15px; 
z-index: 4;
`;





const Whatsapp = () => (
  <>
   
   
  <SectionNew>
         <a href ="https://wa.me/447707717740"><img className=""  src={imgC4}  width="55px"/></a><br />
         
         </SectionNew>
        
       
  </>
);

export default Whatsapp;
