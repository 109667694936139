import React from 'react'
import {Link} from 'gatsby'
import ReactWOW from 'react-wow'
import service4 from '../../assets/images/m2.jpg'
import service5 from '../../assets/images/services/service5.png'
import service6 from '../../assets/images/services/service6.png'
import RoomBanner from './RoomBanner'
import Video from '../youtubevideo'


const Aboutm = () => {
    return (
        <section id="about" className="overview-area ptb-100 pt-100 ">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
                <div className="overview-content">
                        <div className="content right-content">
                            
                            
                            <Video
        videoSrcURL="https://www.youtube.com/embed/4i9NklKE1RQ"
        videoTitle="Flexible Treatment Rooms to rent in London - Minsony"
      />

                            <br />
                        </div>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            
                            <h2>Why Choose Us</h2>
                     

                            <ul className="features-list">
                            <li><span><i className='bx bx-check'></i> Easy online booking <p className="smalltext">User friendly booking system</p></span></li>
                            
                            
                            <li><span><i className='bx bx-check'></i> Affordable & Flexible <p className="smalltext">Book just what you need</p></span></li>
                            <li><span><i className='bx bx-check'></i> Prime location <p className="smalltext">Minutes walk from 7 main train lines
</p></span></li>
                                <li><span><i className='bx bx-check'></i>   Private entrance <p className="smalltext">Privacy & comfort is crucial</p></span></li>
                        
   
                                <li><span><i className='bx bx-check'></i> No commitment <p className="smalltext">No lengthy contracts</p></span></li>
                          
                                <li><span><i className='bx bx-check'></i> No hidden fees <p className="smalltext">All bills included</p>	</span></li>
                           
                               
                               
                            </ul>
                          
                        </div>
                    </div>
                   
                    
                </div>
                {/* End Left Image Style */}

                {/* Right Image Style */}
               
               
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Aboutm