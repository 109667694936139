import React from 'react'
import {Link} from 'gatsby'
import ReactWOW from 'react-wow'
import service4 from '../../assets/images/m2.jpg'
import service5 from '../../assets/images/services/service5.png'
import service6 from '../../assets/images/services/service6.png'
import RoomBanner from './RoomBanner'

const Rooms = () => {
    return (
        <section id="rooms" className="overview-area2 ptb-100 pt-100 ">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
                <div className="overview-image">
                        <div className="image">
                          <RoomBanner />
                        </div>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            
                            <h2>Our Treatment Rooms London</h2>
                            <p>An optimal solution for individuals aspiring to own a
clinic, our space offers an attractive alternative to the
typical overhead costs associated with traditional
setups, such as high rents, business rates and bills. <br /><br />At
Minsony, we understand the importance of flexibility
in your operations. That's why we offer a booking
system that caters to your needs.
What sets us apart is our commitment to flexibility.
We understand that your scheduling requirements
may vary, and we're here to accommodate them. <br /><br />
Whether you need a space for an hour, a day, a week,
or an extended period, our booking system is
designed to be adaptable.
Additionally, our all-inclusive billing approach
simplifies your financial management, allowing you to
focus on what matters most – your clinic and clients.
Rooms benefit from the following:</p>

                            <ul className="features-list">
                            <li><span><i className='bx bx-check'></i> Wifi 	</span></li>
                                
                        
                                
                  
           
                            <li><span><i className='bx bx-check'></i> Wash basin</span></li>
                                <li><span><i className='bx bx-check'></i> Treatment bed</span></li>
                                <li><span><i className='bx bx-check'></i> Storage </span></li>
                                <li><span><i className='bx bx-check'></i> Large windows </span></li>
                                <li><span><i className='bx bx-check'></i> Air conditioning </span></li>
                                
                               
                               
                            </ul>
                       
                        </div>
                    </div>
                   
                    
                </div>
                {/* End Left Image Style */}

                {/* Right Image Style */}
               
               
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Rooms