import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'


const FAQ = () => {
    return (
        
            <div id="faq" className="ptb-100 overview-area3">
                <div className="container">
                <h2>FAQ</h2> 
                    <div className ="row">
                    
                    <div className="col-lg-6 col-md-12">
                    <div className="faq-accordion">
                    <br />
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Finding availability?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    All availability is shown in the booking calendar. Simply click the ‘book now’ to see the availability for each tratment room.

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Can I book a specific treatment room?

                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                             Yes, you can book any treatment room that is available.

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Minimum treatment room booking duration?

                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    We have no minimum duration, you can book a treatment room for just 1 hour if that is all you need.

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Are there any other costs?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    No. What you see is what you pay. Wifi and all bills are included in the price quoted.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    What treatments are permitted?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    All treatments are welcome.

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        

                           
                        </Accordion>
                        </div>
                        
                        
                        </div>

                        <div className="col-lg-6 col-md-12">
                    <div className="faq-accordion">
                    <br />
                        <Accordion>
                           
                        <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                     Do the treatment rooms offer storage?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Every treatment room has a storage unit that can be used only during your booking.

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    How long in advance do I have to book?

                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    We are very flexible you can book instantly as long as you see availability on the website.

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Cleaning and maintenance?

                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Treatment Rooms are to be left clean and tidy after your booking and in the state in which you expect to find them in. 

Minsony management will be responsible for the maintenance and upkeep of the treatment rooms and will provide each treatment room with tissues, and cleaning materials. 

Please report any damages to treatment rooms immediately. 
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    How do I pay for a treatment room?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    All payments are made by card via the booking system, however you can pay by cash or bank transfer. Contact us for further details.

                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Can I cancel/reschedule my treatment room booking?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Yes, but please do so 72 hours before otherwise a refund will not be issued.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                          
                        </Accordion>
                        </div>
                        
                        
                        </div>
                    </div>
                </div>
            </div>
          
    );
}

export default FAQ